<template>
  <div>
    <div id="map"></div>

    <div class="menu">
      <div class="item" v-for="item in typeList" :key="item.value" :class="selected === item.value ? 'active' : ''"
        @click="onSelect(item.value)">
        {{ item.name }}
      </div>
    </div>

    <Detail :detail="detail" />
  </div>
</template>
<script>
import Detail from "./components/detail.vue";

import request from "@/api/request";
export default {
  components: {
    Detail,
  },

  data() {
    return {
      typeList: [
        {
          name: "cod",
          value: "cod",
        },

        {
          name: "氨氮",
          value: "nh3n",
        },
        {
          name: "酸碱度",
          value: "ph",
        },
        {
          name: "溶解氧",
          value: "do",
        },
        {
          name: "浊度",
          value: "turbidity",
        },
      ],
      selected: "cod",

      list: [],

      detail: {},
    };
  },

  watch: {
    detail: {
      handler() {
        this.addMarker();
      },
      deep: true,
    },
  },
  mounted() {
    this.initMap();

    request({
      url: "/model-analysis/pipe/device/query/realtime/type/waterquality",
    }).then((res) => {
      if (Array.isArray(res) && res.length > 0) {
        this.list = res;
        this.detail = res[0];
        this.addMarker();
      }
    });
  },

  beforeDestroy() {
    this.markers?.forEach((item) => {
      item.off("click", this.onMarkerClick);
    });
    this.map?.clearMap();
    this.map?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 16,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });

      this.map = map;
    },

    addMarker() {
      if (Array.isArray(this.markers)) {
        this.markers.forEach((item) => {
          item.off("click", this.onMarkerClick);
        });
      }
      this.markers = [];
      this.map?.clearMap();

      const key = this.selected;

      const obj = this.typeList.find((item) => item.value === key);

      const markers = this.list.map((item) => {
        let content = "";

        if (item.id === this.detail.id) {
          content = `
                <div style="background-color: #000;white-space: nowrap;border-radius: 100px;border: 2px solid #f00;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
                      <img src='/icon/water-quality-active.png' style="height: 18px;margin-right: 8px;border-radius: 50%;border: 2px solid #f00"  />
                        <span style='color: #f00'>${item.name}${obj ? obj.name : key
            }：${item.realtime ? item.realtime[key] : ""}</span>
              </div>
              `;
        } else {
          content = `
             <div style="background-color: #000;white-space: nowrap;border-radius: 100px;border: 2px solid #9824f8;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
                      <img src='/icon/water-quality-active.png' style="height: 18px;margin-right: 8px"  />
                    <span>${item.name}${obj ? obj.name : key}：${item.realtime ? item.realtime[key] : ""
            }</span>
              </div>
          `;
        }

        const marker = new window.AMap.Marker({
          position: [item.x, item.y],
          content,
          extData: item,
        });

        marker.on("click", this.onMarkerClick);
        return marker;
      });

      this.map.add(markers);
      this.markers = markers;
    },

    onMarkerClick(e) {
      console.log(e);
      this.detail = e.target._opts.extData;
    },

    onSelect(key) {
      this.selected = key;
      this.addMarker();
    },
  },
};
</script>


<style lang="less" scoped>
#map {
  position: fixed;
  height: 105vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.menu {
  position: fixed;
  top: 11vh;
  left: 1vw;
  background-color: #000;
  border-radius: 8px;
  padding: 1vh;
  display: flex;
  align-items: center;
  cursor: pointer;

  .item {
    padding: 4px 12px;
  }

  .active {
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 6px;
  }
}
</style>