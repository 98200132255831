<template>
  <div style="padding: 0 1vw;">
    <a-table bordered :data-source="list" :loading="loading" size="small">
      <a-table-column title="浊" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.turbidity ? text.turbidity : text.turbidity }}</div>
        </template>
      </a-table-column>

      <a-table-column title="cod" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.cod ? text.cod : text.cod }}</div>
        </template>
      </a-table-column>
      <!--
      <a-table-column title="orp" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.orp ? text.orp : text.orp }}</div>
        </template>
      </a-table-column>
    -->
      <a-table-column title="氨氮" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.nh3n ? text.nh3n : text.nh3n }}</div>
        </template>
      </a-table-column>

      <a-table-column title="酸碱度" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.ph ? text.ph : text.ph }}</div>
        </template>
      </a-table-column>
      <a-table-column title="溶解氧" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.do ? text.do : text.do }}</div>
        </template>
      </a-table-column>

      <a-table-column title="更新时间" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.ts }}</div>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>