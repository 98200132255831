<template>

  <div :id="id"></div>
</template>


<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
]);

export default {
  props: {
    id: {
      type: String,
      default: "chart",
    },
    list: {
      type: Array,
      default: () => [],
    },
    rainList: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    list: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
    rainList: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    chartDom.style.height = "30vh";
    chartDom.style.width = "100%";
    this.chart = echarts.init(chartDom);
    this.renderChart();
    //console.log("Waterquality Chart INIT");
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    renderChart() {
      console.log("Waterquality Chart Render");
      //console.log(this.list)
      this.chart?.clear();
      if (!this.list.length) return;
      //if (!this.rainList.length) return;

      var option = {
        grid: {
          top: 50,
          bottom: 80,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              backgroundColor: "#505765",
            },
          },
        },
        legend: {
          textStyle: { color: '#fff' },
          data: [this.list[0].name,
            //this.rainList[0].name
          ],
          // left: 10
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 65,
            end: 85,
          },
          {
            type: "inside",
            realtime: true,
            start: 65,
            end: 85,
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: false },
            data: this.list.map((item) => item.ts),
          },
        ],
        yAxis: [
          {
            name: this.list[0].name,
            type: "value",
          },
          //{
          //  name: this.rainList[0].name,
          //  nameLocation: "start",
          //  alignTicks: true,
          //  type: "value",
          //  inverse: true,
          //},
        ],
        series: [
          {
            name: this.list[0].name,
            type: "line",
            areaStyle: {},
            lineStyle: {
              width: 1,
            },
            emphasis: {
              focus: "series",
            },
            // markArea: {
            //   silent: true,
            //   itemStyle: {
            //     opacity: 0.3
            //   },
            //   data: [
            //     [
            //       {
            //         xAxis: '2009/9/12\n7:00'
            //       },
            //       {
            //         xAxis: '2009/9/22\n7:00'
            //       }
            //     ]
            //   ]
            // },
            data: this.list.map((item) => item.value),
          },
          /* 因为雨量计返回null
          {
            name: this.rainList[0].name,
            type: "line",
            yAxisIndex: 1,
            areaStyle: {},
            lineStyle: {
              width: 1,
            },
            emphasis: {
              focus: "series",
            },
            // markArea: {
            //   silent: true,
            //   itemStyle: {
            //     opacity: 0.3
            //   },
            //   data: [
            //     [
            //       {
            //         xAxis: '2009/9/10\n7:00'
            //       },
            //       {
            //         xAxis: '2009/9/20\n7:00'
            //       }
            //     ]
            //   ]
            // },
            data: this.rainList.map((item) => item.value),
          },
          */
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped></style>