<template>
  <div class="detail">
    <div class="header">
      <div class="title">水质监测站专题</div>
    </div>

    <div class="content" style="padding-top: 2vh; position: relative">
      <Navigator :lng="detail.x" :lat="detail.y" />

      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ detail.code }}</div>
      </div>
      <div class="item">
        <div class="label">名称：</div>
        <div class="value">{{ detail.name }}</div>
      </div>
    </div>

    <a-tabs v-model="activeTab">
      <a-tab-pane key="1" tab="统计数据">
        <a-space style="padding-bottom: 4vh; padding: 0 1vw">
          <a-range-picker size="small" style="width: 13vw" v-model="selectedDate" :allowClear="false" />
          <a-select size="small" placeholder="指标" style="width: 8vw" v-model="queryOption">
            <a-select-option v-for="item in queryOptions" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-select placeholder="间隔" size="small" style="width: 6vw" v-model="interval">
            <a-select-option v-for="item in statInterval" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-button type="primary" size="small" @click="query">查询</a-button>
          <a-button size="small" @click="reset">重置</a-button>
        </a-space>

        <div style="padding-top: 1vh"></div>
        <Chart id="history-chart" :list="chartList" :rainList="rainList" />
        <div style="padding-top: 1vh"></div>

        <Table :list="tableList" :loading="loading" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="实时数据">
        <div class="content">
          <div class="item">
            <div class="label">溶解氧：</div>
            <div class="value">
              {{ realTimeData.do ? realTimeData.do + "mg/L" : "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">浊度：</div>
            <div class="value">
              {{
                realTimeData.turbidity ? realTimeData.turbidity + "NTU" : "--"
              }}
            </div>
          </div>
          <!--
          <div class="item">
            <div class="label">氧化还原电位：</div>
            <div class="value">{{ realTimeData.orp ? realTimeData.orp + "mV" : "--" }}</div>
          </div>
        -->
          <div class="item">
            <div class="label">化学需氧量：</div>
            <div class="value">
              {{ realTimeData.cod ? realTimeData.cod + "mg/L" : "--" }}
            </div>
          </div>

          <div class="item">
            <div class="label">酸碱度：</div>
            <div class="value">
              {{ realTimeData.ph ? realTimeData.ph : "--" }}
            </div>
          </div>

          <div class="item">
            <div class="label">氨氮：</div>
            <div class="value">
              {{ realTimeData.nh3n ? realTimeData.nh3n + "mg/L" : "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">更新时间：</div>
            <div class="value">
              {{ realTimeData.ts ? realTimeData.ts : "--" }}
            </div>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import Chart from "./chart.vue";
import Table from "./table.vue";
import moment from "moment";
import request from "@/api/request";
import { mapGetters } from "vuex";

import Navigator from "@/components/navigator";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Chart,
    Table,
    Navigator,
  },

  data() {
    return {
      activeTab: "1",

      selectedDate: [moment().subtract(1, "months"), moment()],
      queryOption: "cod",
      queryOptions: [
        {
          name: "cod",
          value: "cod",
        },
        //{
        //  name: "orp",
        //  value: "orp",
        //},
        {
          name: "氨氮",
          value: "nh3n",
        },
        {
          name: "酸碱度",
          value: "ph",
        },
        {
          name: "溶解氧",
          value: "do",
        },

        {
          name: "浊度",
          value: "turbidity",
        },
      ],
      interval: "1d",

      loading: false,
      chartList: [],
      rainList: [],
      tableList: [],

      realTimeData: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statInterval() {
      return this.findDataDict("statInterval");
    },
  },

  watch: {
    detail: {
      handler() {
        if (this.detail.sn) {
          this.getRealTimeData();
          this.getHistoryData();
        }
      },
      deep: true,
    },
  },

  methods: {
    getHistoryData() {
      this.loading = true;

      request({
        url: "/data-service/dev/stat",
        method: "post",
        data: {
          devType: "waterquality",
          beginDate: this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
          endDate: this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
          eids: [this.detail.sn],
          interval: this.interval,
        },
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.tableList = res;

            this.chartList = res.map((item) => {
              return {
                ts: item.ts,
                name: this.queryOptions.find(
                  (item) => item.value === this.queryOption
                ).name,
                value: item[this.queryOption],
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });

      request({
        url: "/model-analysis/pipe/device/query/type/rainFall",
      }).then((res) => {
        console.log("rain config", res);

        if (Array.isArray(res) && res.length > 0) {
          if (res[0].sn) {
            request({
              url: "/data-service/dev/stat",
              method: "post",
              data: {
                devType: "rainFall",
                beginDate:
                  this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
                endDate:
                  this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
                eids: [res[0].sn],
                interval: this.interval,
              },
            }).then((res) => {
              console.log("flow detail", res);
              if (Array.isArray(res)) {
                this.rainList = Object.freeze(
                  res.map((item) => {
                    return {
                      ts: item.ts,
                      name: "雨量",
                      value: item.rainfall,
                    };
                  })
                );
              }
            });
          }
        }
      });
    },

    query() {
      this.getHistoryData();
    },
    reset() {
      this.selectedDate = [moment().subtract(1, "months"), moment()];
      this.queryOption = "cod";
      this.interval = "1d";
      this.getHistoryData();
    },

    getRealTimeData() {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: this.detail.type, // 获取类型
          eids: [this.detail.sn],
        },
      }).then((res) => {
        this.realTimeData = res || {};
      });
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  height: 87vh;
  overflow-y: auto;
  right: 1vw;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &>.header {
    background-color: var(--theme-color);
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        min-width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }
}
</style>